import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ProjectsPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Projects"
      />
      {/* Page Container */}
      <div className="mt-16 max-w-2xl mx-auto prose">
        <h2>Projects</h2>
        <div className="mt-16">
        My name is Dayyan. I&apos;m a software engineer with a focus on machine learning.
        </div>
      </div>
    </Layout>
  );
}

export default ProjectsPage;
